import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';
import { graphql, useStaticQuery } from 'gatsby';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import type { ContentfulPageCardsGraphQLResponse } from './types';

const ContentfulCardsQuery = graphql`
  query ContentfulCards {
    allContentfulPageCard {
      nodes {
        localeLang: node_locale
        cardIdentifier
        image {
          fixed {
            src
            width
            height
          }
          file {
            contentType
            url
          }
          title
        }
        title
        richText {
          raw
        }
      }
    }
  }
`;

/** Returns all cards or cards that start with the matcher from the Contentful Gatsby space for you to filter/find from there */

export const useContentfulCards = (identifierStartsWith?: string) => {
  const { allContentfulPageCard }: ContentfulPageCardsGraphQLResponse =
    useStaticQuery(ContentfulCardsQuery);

  const { currentLocale } = useLocalizationContext();

  const filteredCards = useMemo(() => {
    const localisedCards = allContentfulPageCard.nodes.filter(
      card => card.localeLang === currentLocale.lang
    );

    const sortedCards = sortBy(localisedCards, 'cardIdentifier');

    if (!identifierStartsWith) return sortedCards;
    return sortedCards.filter(card => card.cardIdentifier.startsWith(identifierStartsWith));
  }, [identifierStartsWith, allContentfulPageCard.nodes, currentLocale.lang]);

  if (!filteredCards || filteredCards.length === 0) {
    throw new Error(`Could not find any Contentful Page Cards`);
  }

  return filteredCards;
};
