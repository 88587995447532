import { SignupLink } from 'components/Links';
import { CTANotice } from 'components/Notice';
import type { FC } from 'react';

export const CampaignPageCTA: FC<{
  ctaHeader: string;
  ctaSubheader?: string;
  ctaButtonText?: string;
  hasMicetypeCta?: boolean;
}> = ({ ctaHeader, ctaSubheader, ctaButtonText, hasMicetypeCta }) => (
  <CTANotice
    header={ctaHeader}
    note={
      <>
        {ctaSubheader}
        {hasMicetypeCta && <sup>1</sup>} .
      </>
    }
    button={ctaButtonText ? <SignupLink asButton>{ctaButtonText}</SignupLink> : undefined}
  />
);
