import { useMemo } from 'react';

import { partition } from 'lodash';
import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { useContentfulCards } from 'hooks/useContentfulCards';
import { useContentfulSections } from 'hooks/useContentfulSections';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { SignupLink } from 'components/Links';
import { Cards } from 'components/Cards';
import { Container, Section, SectionVideo } from 'components/Container';
import { TrustPilot } from 'components/TrustPilot';
import { ImageBlock } from 'components/ImageBlock';
import { ContentBlock } from 'components/ContentBlock';
import { RichText } from 'components/RichText';
import { PricingBlock } from 'components/PricingBlock';
import { CampaignPageCTA } from 'components/CampaignPage/CampaignPageCTA';
import { FlexContainer } from 'components/FlexContainer';

import {
  portfolioTrackerCards,
  uploadHoldingsCards,
  usePortfolioTrackerCards,
} from 'page-data/portfolio-tracker';

import type { FC } from 'react';
import type { ContentfulCampaignPage } from 'types/campaign/campaign';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const CampaignPage: FC<
  LocalizedPageProps & {
    pageContext: {
      campaignPage: ContentfulCampaignPage;
    };
  }
> = ({
  pageContext: {
    campaignPage: {
      title,
      description,
      ctaHeader,
      ctaSubheader,
      ctaButtonText,
      displayAwardImages,
      displayFeaturedInImages,
      micetypeCta,
      pricingDiscountPercentage,
    },
  },
}) => {
  const {
    translations: { builtForInvestors, investorsJustLikeYou, freeUsers },
  } = useContentfulLocale();

  const sections = useContentfulSections('affiliate-campaign-');
  const [[heroSection], otherSections] = useMemo(
    () => partition(sections, ['sectionIdentifier', 'affiliate-campaign-1']),
    [sections]
  );

  // cards
  const { trustedCards } = usePortfolioTrackerCards();
  const localisedTaxCards = useContentfulCards('tax-alt-');

  /** This is a CTA we mount multiple times into this page; rendered here to avoid duplication */
  const renderedCTA = useMemo(
    () => (
      <CampaignPageCTA
        ctaHeader={ctaHeader}
        ctaSubheader={ctaSubheader}
        ctaButtonText={ctaButtonText}
        hasMicetypeCta={!!micetypeCta}
      />
    ),
    [ctaHeader, ctaSubheader, ctaButtonText, micetypeCta]
  );

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        socialTitle={title}
        socialDescription={description}
      />
      {heroSection && (
        <ContentBlock
          isHero
          header={heroSection.header}
          textContent={heroSection.textContent}
          image={heroSection.image}
          button={<SignupLink asButton />}
        />
      )}
      {renderedCTA}
      <Cards cards={portfolioTrackerCards} />
      <Container>
        <Section>
          <h2>Swap your investment spreadsheet for Sharesight</h2>
          <p>
            Managing your investment portfolio used to involve hours spent manually updating a
            spreadsheet. Online investment portfolio tools like Sharesight make it easy.
          </p>
        </Section>
        <SectionVideo videoUrl="https://www.youtube.com/watch?v=IIgxxIfHOis" />
      </Container>
      {renderedCTA}
      <Container skipColoration background="cream">
        <h2>Powerful investment performance & tax reports</h2>
      </Container>

      {otherSections &&
        otherSections.map(section => (
          <ContentBlock
            key={section.sectionIdentifier}
            header={section.header}
            textContent={section.textContent}
            image={section.image}
          />
        ))}

      <Cards
        cards={localisedTaxCards}
        header={builtForInvestors}
        subHeader={investorsJustLikeYou}
      />
      {renderedCTA}
      <Cards
        header="Upload your holdings, and we’ll take it from there"
        cards={uploadHoldingsCards}
      />
      <TrustPilot />
      <Cards header={`Trusted by ${freeUsers} investors just like you`} cards={trustedCards} />
      <PricingBlock pricingDiscountPercentage={pricingDiscountPercentage} />
      {displayAwardImages && <ImageBlock variation="awards" />}
      {displayFeaturedInImages && <ImageBlock variation="featured" />}
      {micetypeCta?.textContent && (
        <Container background="white">
          <FlexContainer>
            <sub>1</sub>
            <RichText richText={micetypeCta.textContent} />
          </FlexContainer>
        </Container>
      )}
    </Layout>
  );
};
export default CampaignPage;
